// Mobile menu icon
.nav-icon {
	width: 42px;
	height: 27px;
    @apply relative inline-block cursor-pointer;
}

.nav-icon__inner,
.nav-icon__inner:before,
.nav-icon__inner:after {
    content: '';
    height: 3px;
    width: 100%;
    @apply absolute block left-0 bg-black transition-colors duration-300 ease-in-out;
    .nav-icon:hover & {
        @apply bg-green-dark;
    }
}

.modal-open,
.bg-blue,
.bg-green  {
    .nav-icon:hover {
        .nav-icon__inner,
        .nav-icon__inner:before,
        .nav-icon__inner:after {
            @apply bg-white;
        }
    }
}

.modal-open .nav-icon.nav-icon--active {
     .nav-icon__inner {
        background-color: transparent;
    }
}

.nav-icon__inner {
    top: 0;
}

.nav-icon__inner:before {
	top: 12px;
    .nav-icon--active & {
        top: 12px;
        transform: rotate(45deg);
    }
}

.nav-icon__inner:after {
	top: 24px;
    .nav-icon--active & {
        top: 12px;
        transform: rotate(-45deg);
        width: 100%;
    }
}


// Mobile navigation modal
.modal-open {
    @apply overflow-hidden;
}

.mobile {
    visibility: hidden;
    max-width: 500px;
    transform: translateX(100%);
    transition-property: transform, visibility;
    @apply block overflow-auto fixed top-0 right-0 bottom-0 w-full bg-green z-30 duration-500 ease-in-out;
}

.mobile--active {
    visibility: visible;
    transform: translateX(0%);
}

// Nav
.primary-nav {
    @apply pb-12;
}

.primary-nav__link,
.secondary-nav__link {
    font-size: 17px;
    z-index: 3;
    @apply relative block py-3 border-b border-solid border-black;
    &:hover {
        @apply text-white;
    }
}

.primary-nav__item--active {
    @apply font-bold;
}

.primary-nav__link--has-children:before,
.primary-nav__link--has-children:after  {
    content: '';
    width: 18px;
    height: 2px;
    top: 50%;
    right: 0;
    transform: translateX(-50%) translateY(-50%);
    @apply block absolute bg-black transition-all duration-300 ease-in-out;
}

.primary-nav__link--has-children:after {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.primary-nav__link--has-children.is-active:after {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.primary-nav__link--button {
    @extend .button;
    @apply block mt-10 text-center;
    &:hover {
        // @apply text-green-dark;
    }
}

.secondary-nav {
    visibility: hidden;
    height: auto;
    max-height: 0;
    z-index: 2;
    transform: translateY(-10px);
    // transition-property: opacity, transform, max-height, height, margin;
    @apply overflow-hidden ml-8 opacity-0 bg-green transition-all duration-500 ease-in-out;
}

.secondary-nav.is-active {
    visibility: visible;
    max-height: 1000px;
    transform: translateY(0);
    @apply opacity-100 pb-2;
}


// Mobile overlay
.mobile-overlay {
    height: 0;
    z-index: 1;
    overflow: hidden;
    @apply absolute top-0 bottom-0 left-0 right-0 opacity-0 bg-black bg-opacity-75 transition-opacity duration-500 ease-in-out;
}

.modal-open .mobile-overlay {
    height: auto;
    @apply opacity-100;
}
