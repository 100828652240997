// Accordions
.accordion__item {
	@apply relative border-l border-r border-solid border-black;
}

.accordion__item:first-of-type {
    @apply border-t;
}

.accordion__title {
	@apply relative block px-4 py-2 border-b border-solid border-black;
    @screen lg {
        @apply px-7 py-4;
    }
}

.accordion__title:hover {
    @apply bg-black text-white cursor-pointer;
}

.accordion__icon {
    width: 39px;
    // z-index: -1;
    @apply relative block absolute h-full top-0 right-0 border-l border-solid border-black;
    @screen lg {
        width: 59px;
    }
    .accordion__title:hover & {
        @apply bg-black border-white;
    }
}

.accordion__icon:before,
.accordion__icon:after  {
    content: '';
    width: 18px;
    height: 4px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @apply block absolute bg-black transition-all duration-300 ease-in-out;
    @screen lg {
        width: 20px;
        height: 4px;
    }
    .accordion__title:hover & {
        @apply bg-white;
    }
}

.accordion__icon:after {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    .is-active & {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
}

.accordion__content {
    max-height: 0;
    margin-top: -1px;
    transition-property: opacity, height, max-height;
	@apply overflow-hidden px-4 opacity-0 border-b border-solid border-black duration-300 ease-in-out;
    @screen lg {
        @apply px-6;
    }
}

.accordion__content.is-active {
    max-height: 1000px;
    @apply opacity-100 py-3;
    @screen lg {
        @apply py-6;
    }
}
