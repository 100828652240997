// Links and buttons
.button {
    line-height: 1;
    @apply inline-block px-6 py-3 bg-black text-white font-bold uppercase border border-black;
    @screen md {
        @apply py-4;
    }
    @screen lg {
        @apply py-5;
    }
    @screen xxl {
        font-size: 18px;
    }
    &:hover {
        @apply bg-transparent text-black;
    }
}

.link {
    font-size: 17px;
    @apply font-bold;
    @screen md {
        font-size: 19px;
    }
    @screen xxl {
        font-size: 21px;
    }
    &:hover {
        @apply text-green-dark;
    }
}

.download-link {
    @apply relative block pl-14 pr-6 py-2 border border-solid border-black;
    @screen md {
        @apply pl-16 py-4;
    }
    svg {
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        @apply absolute fill-black;
        @screen md {
            left: 32px;
        }
    }
}

.download-link:hover {
    @apply bg-black text-white;
    svg  {
        @apply fill-white;
    }
}

.download-link + .download-link {
    margin-top: -1px;
}

// Reverse styles
.bg-blue,
.bg-green {
    .link:hover {
        @apply text-white;
    }
}
.similar-items-link{
    position: absolute;
    bottom: 0;
}