/* purgecss start ignore */
.button, .primary-nav__link--button, .main-nav__link--button {
  line-height: 1;
  @apply inline-block px-6 py-3 bg-black text-white font-bold uppercase border border-black;
}
@screen md {
  .button, .primary-nav__link--button, .main-nav__link--button {
    @apply py-4;
  }
}
@screen lg {
  .button, .primary-nav__link--button, .main-nav__link--button {
    @apply py-5;
  }
}
@screen xxl {
  .button, .primary-nav__link--button, .main-nav__link--button {
    font-size: 18px;
  }
}
.button:hover, .primary-nav__link--button:hover, .main-nav__link--button:hover {
  @apply bg-transparent text-black;
}

.link {
  font-size: 17px;
  @apply font-bold;
}
@screen md {
  .link {
    font-size: 19px;
  }
}
@screen xxl {
  .link {
    font-size: 21px;
  }
}
.link:hover {
  @apply text-green-dark;
}

.download-link {
  @apply relative block pl-14 pr-6 py-2 border border-solid border-black;
}
@screen md {
  .download-link {
    @apply pl-16 py-4;
  }
}
.download-link svg {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  @apply absolute fill-black;
}
@screen md {
  .download-link svg {
    left: 32px;
  }
}

.download-link:hover {
  @apply bg-black text-white;
}
.download-link:hover svg {
  @apply fill-white;
}

.download-link + .download-link {
  margin-top: -1px;
}

.bg-blue .link:hover,
.bg-green .link:hover {
  @apply text-white;
}

.similar-items-link {
  position: absolute;
  bottom: 0;
}

.header {
  @apply relative z-10;
}
@screen md {}
@screen lg {}

.main-nav__item {
  line-height: 1;
  @apply relative inline-block;
}

.main-nav__item + .main-nav__item {
  @apply ml-8;
}

.main-nav__item--active {
  @apply font-bold;
}

.main-nav__link {
  @apply inline-block pb-4;
}

.main-nav__item:hover .main-nav__link {
  @apply text-green-dark;
}

.bg-blue .main-nav__item:hover .main-nav__link,
.bg-green .main-nav__item:hover .main-nav__link {
  @apply text-white;
}
.bg-blue .main-nav__item:hover .main-nav__link--button,
.bg-green .main-nav__item:hover .main-nav__link--button {
  @apply text-green-dark;
}

.sub-nav {
  height: 0;
  width: 300px;
  left: -32px;
  overflow: hidden;
  transform: translateY(-10px);
  transition-property: border, opacity, transform;
  @apply absolute opacity-0 bg-white duration-500 ease-in-out;
}

.has-children:hover .sub-nav,
.has-children:focus-within .sub-nav {
  overflow: visible;
  transform: translateY(0);
  height: auto;
  @apply opacity-100;
}

.sub-nav:after,
.sub-nav:before {
  bottom: calc(100% - 1px);
  left: 24%;
  content: "";
  @apply absolute w-0 h-0 pointer-events-none border-solid border-transparent;
}

.sub-nav:after {
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.sub-nav:before {
  border-color: transparent;
  border-bottom-color: #000;
  border-width: 11px;
  margin-left: -11px;
}

.sub-nav__item:first-child {
  @apply border-t border-solid border-black;
}

.sub-nav__link {
  font-size: 16px;
  padding: 14px 20px;
  @apply block border-b border-r border-l border-solid border-black;
}

.sub-nav__item:not(:first-child) .sub-nav__link--active,
.sub-nav__link:hover {
  @apply bg-black text-white;
}

@screen md {
  .footer {
    background-image: url("/img/footer-blobs.svg");
    background-size: 50%;
    @apply bg-no-repeat bg-right-top pb-20;
  }
}
@screen lg {
  .footer {
    @apply pt-12 pb-28;
  }
}
@screen xl {
  .footer {
    @apply pt-20 pb-32;
    background-size: auto;
  }
}

@screen md {
  .bg-green .footer,
.bg-blue .footer {
    background-image: url("/img/footer-blobs-white.svg");
  }
}

.footer__title {
  @apply inline-block mb-6;
}
@screen xl {
  .footer__title {
    @apply mb-12;
  }
}

.footer__text {
  font-size: 16px;
}

.bg-blue .footer__title a,
.bg-green .footer__title a {
  @apply text-white;
}
.bg-blue .footer__title a:hover,
.bg-green .footer__title a:hover {
  @apply text-black;
}

.nav-icon {
  width: 42px;
  height: 27px;
  @apply relative inline-block cursor-pointer;
}

.nav-icon__inner,
.nav-icon__inner:before,
.nav-icon__inner:after {
  content: "";
  height: 3px;
  width: 100%;
  @apply absolute block left-0 bg-black transition-colors duration-300 ease-in-out;
}
.nav-icon:hover .nav-icon__inner,
.nav-icon:hover .nav-icon__inner:before,
.nav-icon:hover .nav-icon__inner:after {
  @apply bg-green-dark;
}

.modal-open .nav-icon:hover .nav-icon__inner,
.modal-open .nav-icon:hover .nav-icon__inner:before,
.modal-open .nav-icon:hover .nav-icon__inner:after,
.bg-blue .nav-icon:hover .nav-icon__inner,
.bg-blue .nav-icon:hover .nav-icon__inner:before,
.bg-blue .nav-icon:hover .nav-icon__inner:after,
.bg-green .nav-icon:hover .nav-icon__inner,
.bg-green .nav-icon:hover .nav-icon__inner:before,
.bg-green .nav-icon:hover .nav-icon__inner:after {
  @apply bg-white;
}

.modal-open .nav-icon.nav-icon--active .nav-icon__inner {
  background-color: transparent;
}

.nav-icon__inner {
  top: 0;
}

.nav-icon__inner:before {
  top: 12px;
}
.nav-icon--active .nav-icon__inner:before {
  top: 12px;
  transform: rotate(45deg);
}

.nav-icon__inner:after {
  top: 24px;
}
.nav-icon--active .nav-icon__inner:after {
  top: 12px;
  transform: rotate(-45deg);
  width: 100%;
}

.modal-open {
  @apply overflow-hidden;
}

.mobile {
  visibility: hidden;
  max-width: 500px;
  transform: translateX(100%);
  transition-property: transform, visibility;
  @apply block overflow-auto fixed top-0 right-0 bottom-0 w-full bg-green z-30 duration-500 ease-in-out;
}

.mobile--active {
  visibility: visible;
  transform: translateX(0%);
}

.primary-nav {
  @apply pb-12;
}

.primary-nav__link,
.secondary-nav__link {
  font-size: 17px;
  z-index: 3;
  @apply relative block py-3 border-b border-solid border-black;
}
.primary-nav__link:hover,
.secondary-nav__link:hover {
  @apply text-white;
}

.primary-nav__item--active {
  @apply font-bold;
}

.primary-nav__link--has-children:before,
.primary-nav__link--has-children:after {
  content: "";
  width: 18px;
  height: 2px;
  top: 50%;
  right: 0;
  transform: translateX(-50%) translateY(-50%);
  @apply block absolute bg-black transition-all duration-300 ease-in-out;
}

.primary-nav__link--has-children:after {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.primary-nav__link--has-children.is-active:after {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.primary-nav__link--button {
  @apply block mt-10 text-center;
}
.secondary-nav {
  visibility: hidden;
  height: auto;
  max-height: 0;
  z-index: 2;
  transform: translateY(-10px);
  @apply overflow-hidden ml-8 opacity-0 bg-green transition-all duration-500 ease-in-out;
}

.secondary-nav.is-active {
  visibility: visible;
  max-height: 1000px;
  transform: translateY(0);
  @apply opacity-100 pb-2;
}

.mobile-overlay {
  height: 0;
  z-index: 1;
  overflow: hidden;
  @apply absolute top-0 bottom-0 left-0 right-0 opacity-0 bg-black bg-opacity-75 transition-opacity duration-500 ease-in-out;
}

.modal-open .mobile-overlay {
  height: auto;
  @apply opacity-100;
}

.accordion__item {
  @apply relative border-l border-r border-solid border-black;
}

.accordion__item:first-of-type {
  @apply border-t;
}

.accordion__title {
  @apply relative block px-4 py-2 border-b border-solid border-black;
}
@screen lg {
  .accordion__title {
    @apply px-7 py-4;
  }
}

.accordion__title:hover {
  @apply bg-black text-white cursor-pointer;
}

.accordion__icon {
  width: 39px;
  @apply relative block absolute h-full top-0 right-0 border-l border-solid border-black;
}
@screen lg {
  .accordion__icon {
    width: 59px;
  }
}
.accordion__title:hover .accordion__icon {
  @apply bg-black border-white;
}

.accordion__icon:before,
.accordion__icon:after {
  content: "";
  width: 18px;
  height: 4px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  @apply block absolute bg-black transition-all duration-300 ease-in-out;
}
@screen lg {
  .accordion__icon:before,
.accordion__icon:after {
    width: 20px;
    height: 4px;
  }
}
.accordion__title:hover .accordion__icon:before,
.accordion__title:hover .accordion__icon:after {
  @apply bg-white;
}

.accordion__icon:after {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
.is-active .accordion__icon:after {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.accordion__content {
  max-height: 0;
  margin-top: -1px;
  transition-property: opacity, height, max-height;
  @apply overflow-hidden px-4 opacity-0 border-b border-solid border-black duration-300 ease-in-out;
}
@screen lg {
  .accordion__content {
    @apply px-6;
  }
}

.accordion__content.is-active {
  max-height: 1000px;
  @apply opacity-100 py-3;
}
@screen lg {
  .accordion__content.is-active {
    @apply py-6;
  }
}

/* purgecss end ignore */