// Footer
.footer {
    @screen md {
        background-image: url('/img/footer-blobs.svg');
        background-size: 50%;
        @apply bg-no-repeat bg-right-top pb-20;
    }
    @screen lg {
        @apply pt-12 pb-28;
    }
    @screen xl {
        @apply pt-20 pb-32;
        background-size: auto;
    }
}

.bg-green .footer,
.bg-blue .footer {
    @screen md {
        background-image: url('/img/footer-blobs-white.svg');
    }
}

.footer__title {
    @apply inline-block mb-6;
    @screen xl {
        @apply mb-12;
    }
}

.footer__text {
    font-size: 16px;
}

// Reverse styles
.bg-blue,
.bg-green {
    .footer__title a {
        @apply text-white;
        &:hover {
            @apply text-black;
        }
    }
}
