// Header
.header {
    @apply relative z-10;
    @screen md {
    }
    @screen lg {
    }
}

.main-nav__item {
    line-height: 1;
    @apply relative inline-block;
}

.main-nav__item + .main-nav__item {
    @apply ml-8;
}

.main-nav__item--active {
    @apply font-bold;
}

.main-nav__link {
    @apply inline-block pb-4;
}

.main-nav__item:hover .main-nav__link {
    @apply text-green-dark;
}

.main-nav__link--button {
    @extend .button;
}


// Reverse styles
.bg-blue,
.bg-green {
    .main-nav__item:hover .main-nav__link {
        @apply text-white;
    }
    .main-nav__item:hover .main-nav__link--button {
        @apply text-green-dark;
    }
}


// Dropdown sub navigation
.sub-nav {
    height: 0;
    width: 300px;
    left: -32px;
    overflow: hidden;
    transform: translateY(-10px);
    transition-property: border, opacity, transform;
    @apply absolute opacity-0 bg-white duration-500 ease-in-out;
}

.has-children:hover .sub-nav,
.has-children:focus-within .sub-nav {
    overflow: visible;
    transform: translateY(0);
    height: auto;
    @apply opacity-100;
}

.sub-nav:after,
.sub-nav:before {    // Sub nav arrow
    bottom: calc(100% - 1px);
    left: 24%;
    content: '';
    @apply absolute w-0 h-0 pointer-events-none border-solid border-transparent;
}

.sub-nav:after {
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
}

.sub-nav:before {
	border-color: transparent;
	border-bottom-color: #000;
	border-width: 11px;
	margin-left: -11px;
}

.sub-nav__item:first-child {
    @apply border-t border-solid border-black;
}

.sub-nav__link {
    font-size: 16px;
    padding: 14px 20px;
    @apply block border-b border-r border-l border-solid border-black;
}

.sub-nav__item:not(:first-child) .sub-nav__link--active,
.sub-nav__link:hover {
    @apply bg-black text-white;
}
